import React, { useEffect, useState } from 'react';
import Homebar from './Homebar';
import IndexPreviewGraph from './IndexPreviewGraph';
import IndexSubGraphs from './IndexSubGraphs';
import { supabase } from '../supabaseClient';
import Switch from '@mui/material/Switch'; // Import MUI Switch
import FormControlLabel from '@mui/material/FormControlLabel';

const IndicesPage = () => {
  const [indices, setIndices] = useState([]); // State to hold indices data
  const [loading, setLoading] = useState(true); // State for loading status
  const [error, setError] = useState(null); // State for error handling
  const [dollarVSReturnToggle, setDollarVSReturnToggle] = useState(true); // Toggle for dollar vs. return view

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const { data, error } = await supabase.rpc('get_indices'); // Use .rpc() to call the function
    
        if (error) throw error;
    
        const transformedData = transformData(data);
        setIndices(transformedData);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const parseTimestamp = (isoString) => {
    const cleanIsoString = isoString.replace(/\.\d+/, ''); 
    return new Date(cleanIsoString).getTime();
  };

  function transformData(data) {
    const transformedData = [];
    data.forEach((entry) => {
      const timestamp = parseTimestamp(entry.created_at); 
      const indexData = entry.data;
  
      Object.keys(indexData).forEach((category) => {
        let majorIndex = transformedData.find((item) => item.name === category);
        if (!majorIndex) {
          majorIndex = { name: category, data: [], subIndices: [] };
          transformedData.push(majorIndex);
        }
  
        const subIndexValues = indexData[category][0];
        const totalValue = indexData[category][1] / 100;
  
        if (typeof subIndexValues === "object" && !Array.isArray(subIndexValues)) {
          majorIndex.data.push({ value: totalValue, timestamp });
  
          Object.keys(subIndexValues).forEach((subIndex) => {
            let subIndexEntry = majorIndex.subIndices.find((item) => item.name === subIndex);
            if (!subIndexEntry) {
              subIndexEntry = { name: subIndex, data: [] };
              majorIndex.subIndices.push(subIndexEntry);
            }
  
            subIndexEntry.data.push({
              value: subIndexValues[subIndex] / 100,
              timestamp,
            });
          });
        } else {
          majorIndex.data.push({ value: totalValue, timestamp });
        }
      });
    });
  
    transformedData.forEach((majorIndex) => {
      majorIndex.data.sort((a, b) => a.timestamp - b.timestamp);
      majorIndex.subIndices.forEach((subIndex) => {
        subIndex.data.sort((a, b) => a.timestamp - b.timestamp);
      });
    });
  
    return transformedData;
  }

  if (loading) {
    return <div><Homebar />Loading...</div>;
  }

  if (error) {
    return <div><Homebar />Error: {error}</div>;
  }

  return (
    <div style={{backgroundColor: '#404040'}}>
      <Homebar />
      
      {/* Toggle for Dollar vs. Return view */}
      <FormControlLabel
        control={
          <Switch
            checked={dollarVSReturnToggle}
            onChange={() => setDollarVSReturnToggle(!dollarVSReturnToggle)}
          />
        }
        label="Show Dollar Values"
      />

      <div style={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {indices.map((index) => (
          <div key={index.name} style={{width:'150px', color: '#ffffff'}}>
            <IndexPreviewGraph
              name={index.name}
              data={index.data}
              description={`${index.name} Overview`}
              isMajorIndex={true}
              dollarVSReturnToggle={dollarVSReturnToggle} // Pass toggle state as prop
            />
            {index.subIndices.length > 0 && (
              <IndexSubGraphs
                graphs={index.subIndices}
                dollarReturn={dollarVSReturnToggle} // Pass dollarReturn directly here
              />
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default IndicesPage;
