import React, { Component } from 'react';
import { LineChart, Line, XAxis, YAxis, Tooltip, ResponsiveContainer } from 'recharts';
import { format } from 'date-fns';

class IndexPreviewGraph extends Component {
  render() {
    const { data, name, isMajorIndex, dollarVSReturnToggle } = this.props;

    if (!data || data.length === 0) {
      return <div>No data available</div>;
    }

    const currentPrice = data[0].value;

    // Use either dollar or percentage difference based on the dollarVSReturnToggle prop
    const oneHourReturn = dollarVSReturnToggle 
      ? (currentPrice - data[1].value) 
      : ((currentPrice - data[1].value) / data[1].value) * 100;
    const twelveHourReturn = dollarVSReturnToggle 
      ? (currentPrice - data[2].value) 
      : ((currentPrice - data[2].value) / data[2].value) * 100;
    const twentyFourHourReturn = dollarVSReturnToggle 
      ? (currentPrice - data[3].value) 
      : ((currentPrice - data[3].value) / data[3].value) * 100;
    const sevenDayReturn = dollarVSReturnToggle 
      ? (currentPrice - data[4].value) 
      : ((currentPrice - data[4].value) / data[4].value) * 100;

      return (
        <div style={{ width: '100%', display: 'flex' }}>
          <div style={{ alignContent: 'center' }}>
            <div>{name}</div>
            <div>
              ${currentPrice}
              <div>
                <div style={{ color: oneHourReturn > 0 ? 'green' : 'red' }}>
                  {`1H ${dollarVSReturnToggle ? `$${oneHourReturn.toFixed(2)}` : `${oneHourReturn.toFixed(2)}%`}`}
                </div>
                <div style={{ color: twelveHourReturn > 0 ? 'green' : 'red' }}>
                  {`12H ${dollarVSReturnToggle ? `$${twelveHourReturn.toFixed(2)}` : `${twelveHourReturn.toFixed(2)}%`}`}
                </div>
                <div style={{ color: twentyFourHourReturn > 0 ? 'green' : 'red' }}>
                  {`24H ${dollarVSReturnToggle ? `$${twentyFourHourReturn.toFixed(2)}` : `${twentyFourHourReturn.toFixed(2)}%`}`}
                </div>
                <div style={{ color: sevenDayReturn > 0 ? 'green' : 'red' }}>
                  {`7D ${dollarVSReturnToggle ? `$${sevenDayReturn.toFixed(2)}` : `${sevenDayReturn.toFixed(2)}%`}`}
                </div>
              </div>
            </div>
          </div>
        </div>
      );
  }
}

export default IndexPreviewGraph;
