import React, { createContext, useContext, useEffect, useState } from 'react';
import { supabase } from '../supabaseClient';

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [session, setSession] = useState(null);

  useEffect(() => {
    // Get current session using getSession
    const fetchSession = async () => {
      const { data } = await supabase.auth.getSession();
      setSession(data.session); // Set the session from the response
    };

    fetchSession();

    const { data: { subscription } } = supabase.auth.onAuthStateChange((_, session) => {
      setSession(session); // Update session on auth state change
    });

    return () => {
      subscription.unsubscribe(); // Clean up subscription
    };
  }, []);

  return (
    <UserContext.Provider value={{ session }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUserContext = () => useContext(UserContext);
