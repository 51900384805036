import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Homepage from './components/Homepage';
import IndicesPage from './components/IndicesPage';
import IndicesOverview from './components/IndicesOverview';
import { UserProvider } from './context/UserContext';
import { Analytics } from "@vercel/analytics/react";
import { width } from '@mui/system';

function App() {
  return (
    <>
      <UserProvider>
        <Router>
          <div style={{width: '100%'}}>
            <Routes>
              <Route path="/" element={<Homepage />} />
              <Route path="/indices" element={<IndicesPage />} />
              <Route path="/indices-overview" element={<IndicesOverview />} />
              <Route path="/help" element={<Homepage />} />
            </Routes>
          </div>
        </Router>
      </UserProvider>
      <Analytics />
    </>
  );
}

export default App;
