import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useUserContext } from '../context/UserContext';
import { FaWallet } from 'react-icons/fa';
import './Homebar.css';
import { supabase } from '../supabaseClient';
import { maxWidth, width } from '@mui/system';

const Homebar = () => {
  const navigate = useNavigate();
  const { session } = useUserContext();
  const { user } = session || {}; 
  const [balance, setBalance] = useState(0);

  useEffect(() => {
    const fetchUserBalance = async () => {
      if (user) {
        const { data: userData, error } = await supabase
          .from('User')
          .select('balance')
          .eq('id', user.id)
          .single();

        if (error) {
          console.error("Error fetching user balance:", error);
        } else {
          setBalance(userData.balance);
        }
      }
    };

    fetchUserBalance();
  }, [user]);

  return (
    <div className="homebar" sx={{width: '100%'}}>
      <div className="left-section">
        <div className="logo" onClick={() => navigate('/')}>Case-Exchange</div>
        <nav className="navigation">
          <ul>
            <li onClick={() => navigate('/indices')}>Indices</li>
            <li onClick={() => navigate('/indices-overview')}>Indices Overview</li>
          </ul>
        </nav>
      </div>
      <div className="search-bar">
        <input type="text" placeholder="Search for something..." />
      </div>
      <div className="auth-buttons">
        {/* Uncomment if you want to show the balance and auth buttons */}
        {/* {user ? (
          <>
            <div className="balance">
              <FaWallet className="wallet-icon" />  
              <span>${(balance)/100}</span>
            </div>
            <div className="welcome-dropdown">
              <span className="welcome-message">Welcome, {user.email}</span>
              <div className="dropdown">
                <span 
                  className="dropdown-item" 
                  onClick={async () => {
                    await supabase.auth.signOut();
                  }}
                >
                  Sign Out
                </span>
              </div>
            </div>
          </>
        ) : (
          <button onClick={() => navigate('/signin')}>Sign In</button>
        )} */}
      </div>
    </div>
  );
};

export default Homebar;
