import React from 'react';
import { Container, Typography, Paper, Box } from '@mui/material';
import Homebar from './Homebar';
import { Home } from '@mui/icons-material';

const IndicesOverview = () => {
  return (
    <div>
      <Homebar></Homebar>
      <Container maxWidth="lg" sx={{ padding: 2 }}>
        <Box sx={{ mt: 4 }}>
          <Paper elevation={3} sx={{ padding: 4 }}>
            <Typography variant="h4" gutterBottom>
              Our Market Indices
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              At Case-Exchange, we offer a selection of specialized indices to help users navigate the CS2 market:
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>Global CS2 Index</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Coming Soon: An aggregate index covering the most important skins on the market, providing an overall market view and reflecting macroeconomic trends.
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>Skin Value Index</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Uses prices on tradeable skins to smooth volatility, tracking value changes and offering insights into item-specific demand.
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>Rare Item Index</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Covering various item categories and skin types, we have something for everyone.
            </Typography>
            <Typography variant="h6" sx={{ mt: 3 }}>Real-Time Trading Index</Typography>
            <Typography variant="body2" sx={{ mt: 1 }}>
              Updated every 5 minutes, this index provides current market movement insights for active traders. Contact owner for more information on data.
            </Typography>
          </Paper>
        </Box>
      </Container>
    </div>
  );
};

export default IndicesOverview;
