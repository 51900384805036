import React from 'react';
import { Container, Typography, Paper, Box, Button } from '@mui/material';
import Homebar from './Homebar';
import Banner from '../components/Banner';
import { width } from '@mui/system';
import { useNavigate } from 'react-router-dom'; 

const HomePage = () => {
  const navigate = useNavigate(); // Initialize navigate

  return (
    <div sx={{height:'100%', width: '100%', padding: 2, backgroundColor: '#404040'}}>
    <Homebar />
      <div sx={{height:'100%', width: '100%', padding: 2, backgroundColor: '#404040'}}>
        <Container maxWidth={false} sx={{minHeight: '1000px', padding: 2, backgroundColor: '#404040'}}>
          <Box sx={{ mt: 4, mb: 4 }}>
            {/* Optional Banner Component */}
            {/* <Banner /> */}
            <Paper 
              elevation={3} 
              sx={{
                padding: 4,
                textAlign: 'center',
                backgroundColor: '#404040',
                color: 'white',
              }}
            >
              <Typography variant="h3" gutterBottom>
                Welcome to Case-Exchange
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                We serve various public indices for the CS2 market.
                All data is sourced from Skinport's API.
                We use only tradeable items to smooth price volatility.
              </Typography>
              <Button 
                variant="contained" 
                sx={{ 
                  mt: 3, 
                  textDecoration: 'underline', 
                  backgroundColor: '#606060', 
                  '&:hover': { backgroundColor: '#707070' } 
                }}
                onClick={() => navigate('/indices')}
              >
                Explore indices
              </Button>
            </Paper>
          </Box>
        </Container>
      </div>
    </div>
  );
};

export default HomePage;
