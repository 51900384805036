import React from 'react';
import IndexPreviewGraph from './IndexPreviewGraph';

const IndexSubGraphs = ({ graphs, dollarReturn}) => {
  if (!graphs || graphs.length === 0) {
    return <div>No graphs available</div>;
  }

  return (
    <div style={{ display: 'flex', flexWrap: 'wrap', alignContent: 'center' }}>
      {graphs.map((graph, index) => (
        <IndexPreviewGraph 
          key={index}
          data={graph.data} 
          name={graph.name} 
          description={graph.description} 
          dollarVSReturnToggle={dollarReturn} // Pass dollarReturn prop here
        />
      ))}
    </div>
  );
};

export default IndexSubGraphs;
