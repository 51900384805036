import { createClient } from '@supabase/supabase-js';

// Your Supabase URL
const supabaseUrl = 'https://hkqujcngjtifcovczopj.supabase.co';

// Your Supabase anon/public API key
const supabaseKey = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImhrcXVqY25nanRpZmNvdmN6b3BqIiwicm9sZSI6ImFub24iLCJpYXQiOjE3MjMxNzY4NDEsImV4cCI6MjAzODc1Mjg0MX0.6Is2a76FmyrFz4n9clAnzOHsow59T5mWVlNCXR4MDKw';

// Create Supabase client
export const supabase = createClient(supabaseUrl, supabaseKey);
